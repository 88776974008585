<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
    v-if="this.$root.mobile"
    :fullscreen="this.$root.mobile"
    transition="dialog-bottom-transition"
    style="min-height:60% !important"
    hide-overlay
  >
    <v-card>
      <!-- <v-card-title>
        <span class="headline">Edit History</span>
      </v-card-title> -->
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("MF.history.new") }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-form ref="mdHistoryForm" v-model="valid">
            <v-autocomplete
              v-model="mdHistoryForm.type"
              clearable
              outlined
              :items="itemTypes"
              :loading="itemTypesLoading"
              :filter="typeFilter"
              :label="$t('MF.history.form.type')"
              item-text="type"
              item-value="type"
              return-object
              :rules="[v => !!v || $t('MF.history.validation.type')]"
            >
              <template v-slot:selection="data">
                {{ getTranslation(data.item).type }}
              </template>
              <template v-slot:item="data">
                {{ getTranslation(data.item).type }}
              </template>
            </v-autocomplete>

            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="mdHistoryForm.incidentDate"
                  :label="$t('MF.history.form.date')"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  :rules="[v => !!v || $t('MF.history.validation.type')]"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="mdHistoryForm.incidentDate"
                @input="menu2 = false"
              />
            </v-menu>

            <v-textarea
              outlined
              :label="$t('MF.history.form.details')"
              auto-grow
              v-model="mdHistoryForm.details"
              :rules="detailsRules"
            ></v-textarea>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
          {{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialog" persistent max-width="700px" v-else>
    <v-card>
      <v-card-title>
        <span class="headline">{{ $t('MF.history.update') }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="mdHistoryForm" v-model="valid">
            <div class="row">
              <!-- md history type input  -->
              <div class="col-lg-6 col-md-6 col-sm-6">
                <v-autocomplete
                  v-model="mdHistoryForm.history_type"
                  clearable
                  outlined
                  :items="itemTypes"
                  :loading="itemTypesLoading"
                  :filter="typeFilter"
                  :label="$t('MF.history.form.type')"
                  item-text="type"
                  item-value="type"
                  return-object
                  :rules="[v => !!v || $t('MF.history.validation.type')]"
                >
                  <template v-slot:selection="data">
                    {{ getTranslation(data.item).type }}
                  </template>
                  <template v-slot:item="data">
                    {{ getTranslation(data.item).type }}
                  </template>
                </v-autocomplete>
              </div>
              <!-- incident date input -->
              <div class="col-lg-6 col-md-6 col-sm-6">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="mdHistoryForm.incident_date"
                      :label="$t('MF.history.form.date')"
                      readonly
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      :rules="[v => !!v || $t('MF.history.validation.type')]"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="mdHistoryForm.incident_date"
                    @input="menu2 = false"
                  />
                </v-menu>
              </div>
              <!-- details input -->
              <div class="col-lg-6 col-md-6 col-sm-6">
                <v-textarea
                  outlined
                  :label="$t('MF.history.form.details')"
                  auto-grow
                  v-model="mdHistoryForm.history_body"
                  :rules="detailsRules"
                ></v-textarea>
              </div>
            </div>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error darken-1" text @click="close">
          {{ $t('AUTH.GENERAL.CANCEL_BUTTON') }}
        </v-btn>
        <v-btn color="blue darken-1" text @click="submit">
          <b-spinner v-if="submitted" small class="mr-2"></b-spinner>
          {{ $t('AUTH.GENERAL.SUBMIT_BUTTON') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
export default {
  props: ["dialog", "record"],
  data() {
    return {
      valid: false,
      submitted: false,
      loading: false,
      error: "",

      itemTypes: [],
      itemTypesLoading: true,
      selectedType: "",
      menu2: false,
      askStarted: false,
      mdHistoryForm: {
        history_type: "",
        incident_date: "",
        history_body: ""
      },
      // id: '',

      detailsRules: [
        v => !!v || this.$t('MF.history.validation.details'),
        v =>
          (v && v.trim().length >= 10) ||
          this.$t('MF.history.validation.details_length')
      ]
    };
  },
  methods: {
    getTranslation(d) {
      //console.log(d);
      if (d.translation.length > 0) {
        for (var i = 0; i < d.translation.length; i++) {
          if (d.translation[i].lang == this.$root.lang) {
            return d.translation[i];
          }
        }
      } else {
        return d;
      }
    },
    async submit() {
      try {
        if (this.$refs.mdHistoryForm.validate()) {
          this.submitted = true;
          await ApiService.update(
            "items/md_history",
            this.record.id,
            this.mdHistoryForm
          );
          this.submitted = false;
          this.$refs.mdHistoryForm.reset();
          this.$emit("formSubmitted", this.$t('MF.history.form.success'));
        }
      } catch (error) {
        if (error.message == "") {
          this.error = error;
        } else {
          this.error = this.$t('MF.history.form.error');
        }
        console.log(error);
      }
    },
    async getItemTypes() {
      try {
        const { data } = await ApiService.get(
          "items",
          "history_type?fields=id,type,translation.*"
        );
        this.itemTypes = data.data;
        this.itemTypesLoading = false;
      } catch (error) {
        this.errorMsg = error;
        this.error = true;
      }
    },
    typeFilter(item, queryText) {
      return item.type.toLowerCase().includes(queryText.toLowerCase());
    },
    close() {
      this.$emit("closeDialog");
    }
  },
  mounted() {
    this.getItemTypes();
    this.record && Object.assign(this.mdHistoryForm, this.record);
  }
};
</script>
